import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { getRestrictedSites, permitSite } from '../../../api/otherApi';
import FormValidatedSelect from '../FormValidatedSelect';
import ValidatedMultiSelect from '../ValidatedMultiSelect';
import { store } from '../../../utils/store';

export default ({user, currentOrg, didValidate, displaySelection, isQueryPending, onSitePermitted}) => {

  const { dispatch } = useContext(store);

  const [isCopyingSitePermission, setIsCopyingSitePermission] = useState(true);
  const [technicianId, setTechnicianId] = useState('');
  const [displayTechnicians, setDisplayTechnicians] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [restrictedSites, setRestrictedSites] = useState([]);
  const [restrictedSiteOptions, setRestrictedSiteOptions] = useState([]);

  const selectionTypeChanged = () => {
    setIsCopyingSitePermission(!isCopyingSitePermission);
  }

  const grantAccess = (e) => {
    e.preventDefault();
    let sitesToChange = selectedSites;
    sitesToChange = Array.from(sitesToChange, ele => ({
      type: "sites",
      id: ele.value
    }));
    const data = { data: sitesToChange };
    permitSite(user.id, data).then(() => {
      onSitePermitted();
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Permission Granted Successfully'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }

  const selectionsChanged = (e) => {
    let selection = Array.from(e.target.selectedOptions, option => ({
      label: option.label,
      value: option.value
    }));
    selection.forEach(selected => {
      if (selected.value === 'all') {
        selection = restrictedSiteOptions.filter(option => {
          return option.value !== 'all';
        })
      }
    })
    setSelectedSites(selection);
  }

  const removeSiteOption = (item) => {
    let newSelectedSites = _.cloneDeep(selectedSites);
    if (item.value === 'all') {
      setSelectedSites([]);
    } else {
      newSelectedSites = newSelectedSites.filter(option => {
        return option.value !== item.value
      })
      setSelectedSites(newSelectedSites);
    }
  }

  useEffect(() => {
    const filters = '?&filter[organisationId]=' + currentOrg + '&userId=' + user.id;
    getRestrictedSites(user.id, filters).then((response) => {
      setRestrictedSites(response.data)
    })
  }, [])

  useEffect(() => {
    if (restrictedSites) {
      let restrictedSiteOptions = [];
      restrictedSites.forEach(restrictedSite => {
        restrictedSiteOptions.push({
          label: restrictedSite.attributes['business-name'],
          value: restrictedSite.id
        })
      })
      setRestrictedSiteOptions(restrictedSiteOptions);
    }
  }, [restrictedSites])

  return (
    <form id="invite-to-site-form" className="form">
      <div className="form-content">
        <div className="grant-access-type">
          <div className="ember-radio-button round">
            <input type="radio" value="copy-site-permission" name="copy-site-permission" id="copy-site-permission" checked={isCopyingSitePermission} onChange={selectionTypeChanged}/>
            <label htmlFor="copy-site-permission">Copy site permission</label>
          </div>
    
          <div className="ember-radio-button round">
            <input type="radio" value="select-sites" name="select-sites" id="select-sites" checked={!isCopyingSitePermission} onChange={selectionTypeChanged}/>
            <label htmlFor="select-sites">Select sites</label>
          </div>
        </div>
    
        {isCopyingSitePermission ? (
          <div className="row row-animated">
            <FormValidatedSelect 
              type='text'
              name='technicianId'
              options={displayTechnicians}
              value={technicianId}
              didValidate={didValidate}
              labelText='Select User'
            />
          </div>
        ) : (
          <div className="row row-animated">
            <ValidatedMultiSelect
              name='selectedSites'
              options={restrictedSiteOptions}
              selection={selectedSites}
              didValidate={didValidate}
              displaySelection={displaySelection}
              selectionModel='Sites'
              labelText='Select Sites'
              canSelectAll={false}
              onSelectionsChanged={selectionsChanged}
              onRemoveItem={removeSiteOption}
            />
          </div>
        )}
      </div>
      <div className="form-actions">
        <button className="button standard blue edit-actions" disabled={isQueryPending} onClick={grantAccess} >
          Grant Access
        </button>
      </div>
    </form>
  )
}