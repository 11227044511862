import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import FormValidatedInput from '../FormValidatedInput';

export default ({
  inputsDisabled,
  serialNumber,
  fittingType,
  description,
  groupName,
  groupID,
  deviceReference,
  location,
  installDate,
  building,
  level,
  switchboard,
  circuit,
  assetCode,
  didValidate,
  isValidDeviceReference,
  showErrorMessageDeviceReference,
  errorMessageDeviceReference,
  onChangeSerialNumber,
  onChangeFittingType,
  onChangeDescription,
  onChangeGroupName,
  onChangeGroupID,
  onChangeDeviceReference,
  onChangeLocation,
  onChangeInstallDate,
  onChangeBuilding,
  onChangeLevel,
  onChangeSwitchboard,
  onChangeCircuit,
  onChangeAssetCode
}) => {

  

  const [showMarkCommission, setShowMarkCommission] = useState(false);

  const lookupFittingInfo = () => {

  }

  const commission = () => {

  }

  

  return (
    <div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='serialNumber' value={serialNumber} disabled={inputsDisabled} idValidate={didValidate} onBlur={lookupFittingInfo} labelText='Serial Number' onChange={onChangeSerialNumber} />
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='fittingType' value={fittingType} disabled={inputsDisabled} idValidate={didValidate} labelText='Product Code' onChange={onChangeFittingType} />
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='description' value={description} disabled={inputsDisabled} idValidate={didValidate} labelText='Description' onChange={onChangeDescription} />
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='groupName' id="disabled-form-input" value={groupName} disabled={inputsDisabled} idValidate={didValidate} labelText='Group' onChange={onChangeGroupName} />
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='groupID' id="disabled-form-input" value={groupID} disabled={inputsDisabled} idValidate={didValidate} labelText='Group ID' onChange={onChangeGroupID} />
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='deviceReference' value={deviceReference} disabled={inputsDisabled} idValidate={didValidate} labelText='Fitting ID' showErrorMessage={showErrorMessageDeviceReference} errorMessage={errorMessageDeviceReference} onChange={onChangeDeviceReference} />
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='location' value={location} disabled={inputsDisabled} idValidate={didValidate} labelText='Location' onChange={onChangeLocation} />
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='date' name='installDate' value={installDate} maxDate={new Date(moment().add('d', -1))} disabled={inputsDisabled} labelText='Install Date' onChange={onChangeInstallDate} />
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='building' value={building} disabled={inputsDisabled} idValidate={didValidate} labelText='Building' onChange={onChangeBuilding} />
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='level' value={level} disabled={inputsDisabled} idValidate={didValidate} labelText='Level' onChange={onChangeLevel} />
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='switchboard' value={switchboard} disabled={inputsDisabled} idValidate={didValidate} labelText='Switchboard' onChange={onChangeSwitchboard} />
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='circuit' value={circuit} disabled={inputsDisabled} idValidate={didValidate} labelText='Circuit' onChange={onChangeCircuit} />
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='assetCode' value={assetCode} disabled={inputsDisabled} idValidate={didValidate} labelText='Asset Code' onChange={onChangeAssetCode} />
      </div>
      {showMarkCommission && (
        <div className="row commission-fitting">
          <input type="checkbox" id='markCommissioned' onClick={commission} />
          <label for='markCommissioned' className="mark-commissioned-label">Mark fitting as commissioned.</label>
        </div>
      )}
    </div>
  )
}


