import React, { useState, useEffect, useRef, useContext } from 'react';
import { australianStates, countries, newZealandStates } from '../../../utils/variables';
import FormValidatedInput from '../FormValidatedInput';
import FormValidatedSelect from '../FormValidatedSelect';
import delete_icon from '../../../assets/icons/delete.svg';
import FormValidatedWholesalerSelect from '../FormValidatedWholesalerSelect';
import { validateFirstName, validateLastName, validateEmail, validateNumber, validateCountry, validateState, validatePassword, validateNumberOnly } from '../../../utils/validators';
import { store } from '../../../utils/store';
import { getWholesalers } from '../../../api/wholesalerApi';
import { getMe, updateUser } from '../../../api/userApi';
import { signedRequest, uploadFile } from '../../../api/otherApi';

export default ({editPage, onDeleteAccountModal}) => {

  const { dispatch, state } = useContext(store);

  // First Name
  const [firstName, setFirstName] = useState('');
  const [isValidFirstName, setIsValidFirstName] = useState(false);
  const [errorMessageFirstName, setErrorMessageFirstName] = useState('');
  const [showErrorMessageFirstName, setShowErrorMessageFirstName] = useState(false);

  // Last Name
  const [lastName, setLastName] = useState('');
  const [isValidLastName, setIsValidLastName] = useState(false);
  const [errorMessageLastName, setErrorMessageLastName] = useState('');
  const [showErrorMessageLastName, setShowErrorMessageLastName] = useState(false);

  // Email
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const [showErrorMessageEmail, setShowErrorMessageEmail] = useState(false);

  // Mobile
  const [phone, setPhone] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [errorMessagePhone, setErrorMessagePhone] = useState('');
  const [showErrorMessagePhone, setShowErrorMessagePhone] = useState(false);

  // User Country
  const [userCountry, setUserCountry] = useState('');
  const [isValidUserCountry, setIsValidUserCountry] = useState(false);
  const [errorMessageUserCountry, setErrorMessageUserCountry] = useState('');
  const [showErrorMessageUserCountry, setShowErrorMessageUserCountry] = useState(false);

  // State
  const [userState, setUserState] = useState('');
  const [isValidUserState, setIsValidUserState] = useState(false);
  const [errorMessageUserState, setErrorMessageUserState] = useState('');
  const [showErrorMessageUserState, setShowErrorMessageUserState] = useState(false);

  // Postcode
  const [postcode, setPostcode] = useState('');

  // Password
  const [password, setPassword] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState('');
  const [showErrorMessagePassword, setShowErrorMessagePassword] = useState(false);

  const [rfn, setRfn] = useState('');
  const [abn, setAbn] = useState('');
  const [qbcc, setQbcc] = useState('');

  // Report Phone
  const [reportPhone, setReportPhone] = useState('');
  const [isValidReportPhone, setIsValidReportPhone] = useState(false);
  const [errorMessageReportPhone, setErrorMessageReportPhone] = useState('');
  const [showErrorMessageReportPhone, setShowErrorMessageReportPhone] = useState(false);

  const [inputsDisabled, setInputsDisabled] = useState(true);
  const [didValidate, setDidValidate] = useState(false);
  const [imgurl, setImgurl] = useState('');
  const [imginfo, setImginfo] = useState('');
  const [imgupload, setImgupload] = useState(null);
  const [imgsigupload, setImgsigupload] = useState(null);
  const [imgPreview, setImgPreview] = useState('');
  const [imgsigPreview, setImgsigPreview] = useState('');
  const [logox, setLogox] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isQueryPending, setIsQueryPending] = useState(false);
  const [sigimginfo, setSigimginfo] = useState('');
  const [signature, setSignature] = useState(false);
  const [imgbeeninguploadedalready, setImgbeeninguploadedalready] = useState(false);
  const [signatureurl, setSignatureurl] = useState('');
  const [showWholesalerAuthFields, setShowWholesalerAuthFields] = useState(false);
  const [wholesaler, setWholesaler] = useState('other');
  const [username, setUsername] = useState('');
  const [displayWholesalers, setDisplayWholesalers] = useState([]);

  const deletelogo = () => {
    const data = {
      data: {
        id: state.user.id,
        attributes: {
          "imgurl": "",
        },
        relationships: state.user.relationships,
        type: state.user.type
      }
    }

    updateUser(data).then(() => {
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Account Updated'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)

      setInputsDisabled(true);
      setIsEditing(false);
      setImgupload(null);
      setImgsigupload(null)

      getMe().then(res => {
        const user = res.data;
        setTimeout(() => {
          dispatch({ type: 'SET USER', payload: user });
          localStorage.setItem('user', JSON.stringify(user));
        }, 1000) 
      })
    })
  }

  const deletesig = async () => {
    const formDataSig = new FormData();
    formDataSig.append('userid', state.user.id);
    formDataSig.append('file', signatureurl);
    formDataSig.append('deletefile', true);
    formDataSig.append('bucket', 'signature');
    await signedRequest(formDataSig);

    const data = {
      data: {
        id: state.user.id,
        attributes: {
          signatureurl: "",
        },
        relationships: state.user.relationships,
        type: state.user.type
      }
    }

    updateUser(data).then(() => {
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Account Updated'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)

      setInputsDisabled(true);
      setIsEditing(false);
      setImgupload(null);
      setImgsigupload(null)

      getMe().then(res => {
        const user = res.data;
        setTimeout(() => {
          dispatch({ type: 'SET USER', payload: user });
          localStorage.setItem('user', JSON.stringify(user));
        }, 1000) 
      })
    })
  }

  const cancel = (e) => {
    e.preventDefault();
    setInputsDisabled(true);
    setIsEditing(false);
  }

  const save = async () => {
    let sigFile = null;
    let sigFileName = ''; 
    let signatureUrl = '';
    let logoFile = null;
    let logoFileName = ''; 
    let logoUrl = '';
    let blob = '';

    if (imgupload) {
      blob = imgupload.slice(0, imgupload.size, imgupload.type); 
      logoFileName = Math.floor(Math.random() * 100000) + 1 + imgupload.name;
      logoFile = new File([blob], logoFileName, {type: imgupload.type});
      const formDataLogo = new FormData();
      formDataLogo.append('file', logoFileName);
      formDataLogo.append('type', imgupload.type);
      formDataLogo.append('bucket', 'logo');
      let logoUrlRaw = await signedRequest(formDataLogo);
      uploadFile(logoFile, logoUrlRaw);
      logoUrl = logoUrlRaw.slice(0, logoUrlRaw.indexOf('?'));
    }
    
    if (imgsigupload) {
      blob = imgsigupload.slice(0, imgsigupload.size, imgsigupload.type); 
      sigFileName = Math.floor(Math.random() * 100000) + 1 + imgsigupload.name;
      sigFile = new File([blob], sigFileName, {type: imgsigupload.type});
      const formDataSig = new FormData();
      formDataSig.append('userid', state.user.id);
      formDataSig.append('file', sigFileName);
      formDataSig.append('type', imgsigupload.type);
      formDataSig.append('bucket', 'signature');
      let signatureUrlRaw = await signedRequest(formDataSig);
      uploadFile(sigFile, signatureUrlRaw);
      signatureUrl = signatureUrlRaw.slice(0, signatureUrlRaw.indexOf('?'));
    }

    let wholesalerData = {
      data: null
    };

    if (wholesaler !== 'other') {
      wholesalerData.data = {
        id: wholesaler,
        type: 'wholesalers'
      }
    }

    let relationships = state.user.relationships;
    if (relationships.sessions) {
      delete relationships.sessions;
    }
    relationships.wholesaler = wholesalerData;

    const data = {
      data: {
        id: state.user.id,
        attributes: {
          "first-name": firstName,
          "last-name": lastName,
          "email": email,
          "phone": phone,
          "country": userCountry,
          "state": userState,
          "admin": state.user.attributes['admin'],
          "postcode": postcode,
          "rfn": rfn,
          "abn": abn,
          "qbcc": qbcc,
          "reportphone": reportPhone,
          "imgurl": logoUrl ? logoUrl : state.user.attributes['imgurl'],
          "editpage": true,
          "sigfilename": sigFileName ? sigFileName : state.user.attributes['sigfilename'],
          "signatureurl": signatureUrl ? signatureUrl : state.user.attributes['signatureurl']
        },
        relationships: relationships,
        type: state.user.type
      }
    }

    updateUser(data).then(() => {
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Account Updated'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)

      setInputsDisabled(true);
      setIsEditing(false);
      setImgupload(null);
      setImgsigupload(null)

      getMe().then(res => {
        const user = res.data;
        setTimeout(() => {
          dispatch({ type: 'SET USER', payload: user });
          localStorage.setItem('user', JSON.stringify(user));
        }, 1000) 
      })
    }).catch(error => {
      dispatch({ type: 'SET POPUP', payload: 'danger' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: error.response.data.errors });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }

  const edit = () => {
    setInputsDisabled(false);
    setIsEditing(true);
  }

  const checkFirstName = (input) => {
    const validateResult = validateFirstName(input);
    if (validateResult.validated) {
      setShowErrorMessageFirstName(false);
      setErrorMessageFirstName('');
      setIsValidFirstName(true);
    } else {
      setShowErrorMessageFirstName(true);
      setErrorMessageFirstName(validateResult.message);
      setIsValidFirstName(false);
    }
  }

  const checkLastName = (input) => {
    const validateResult = validateLastName(input);
    if (validateResult.validated) {
      setShowErrorMessageLastName(false);
      setErrorMessageLastName('');
      setIsValidLastName(true);
    } else {
      setShowErrorMessageLastName(true);
      setErrorMessageLastName(validateResult.message);
      setIsValidLastName(false);
    }
  }

  const checkEmail = (input) => {
    const validateResult = validateEmail(input);
    if (validateResult.validated) {
      setShowErrorMessageEmail(false);
      setErrorMessageEmail('');
      setIsValidEmail(true);
      return true;
    } else {
      setShowErrorMessageEmail(true);
      setErrorMessageEmail(validateResult.message);
      setIsValidEmail(false);
      return false;
    }
  }

  const checkPhone = (input) => {
    const validateResult = validateNumber(input);
    if (validateResult.validated) {
      setShowErrorMessagePhone(false);
      setErrorMessagePhone('');
      setIsValidPhone(true);
    } else {
      setShowErrorMessagePhone(true);
      setErrorMessagePhone(validateResult.message);
      setIsValidPhone(false);
    }
  }

  const checkPassword = (input) => {
    const validateResult = validatePassword(input);
    if (validateResult.validated) {
      setShowErrorMessagePassword(false);
      setErrorMessagePassword('');
      setIsValidPassword(true);
      return true;
    } else {
      setShowErrorMessagePassword(true);
      setErrorMessagePassword(validateResult.message);
      setIsValidPassword(false);
      return false;
    }
  }
  
  const checkUserCountry = (input) => {
    const validateResult = validateCountry(input);
    if (validateResult.validated) {
      setShowErrorMessageUserCountry(false);
      setErrorMessageUserCountry('');
      setIsValidUserCountry(true);
    } else {
      setShowErrorMessageUserCountry(true);
      setErrorMessageUserCountry(validateResult.message);
      setIsValidUserCountry(false);
    }
  }

  const checkUserState = (input) => {
    const validateResult = validateState(input);
    if (validateResult.validated) {
      setShowErrorMessageUserState(false);
      setErrorMessageUserState('');
      setIsValidUserState(true);
    } else {
      setShowErrorMessageUserState(true);
      setErrorMessageUserState(validateResult.message);
      setIsValidUserState(false);
    }
  }

  const checkReportPhone = (input) => {
    const validateResult = validateNumberOnly(input);
    if (validateResult.validated) {
      setShowErrorMessageReportPhone(false);
      setErrorMessageReportPhone('');
      setIsValidReportPhone(true);
    } else {
      setShowErrorMessageReportPhone(true);
      setErrorMessageReportPhone(validateResult.message);
      setIsValidReportPhone(false);
    }
  }

  const changeFirstName = (e) => {
    setFirstName(e.target.value)
    checkFirstName(e.target.value)
  }

  const changeLastName = (e) => {
    setLastName(e.target.value)
    checkLastName(e.target.value)
  }

  const changeEmail = (e) => {
    setEmail(e.target.value)
    checkEmail(e.target.value)
  }

  const changePhone = (e) => {
    setPhone(e.target.value)
    checkPhone(e.target.value)
  }

  const changePreferredWholesaler = (e) => {
    setWholesaler(e.target.value);
  }

  const changeUserCountry = (e) => {
    setUserCountry(e.target.value)
    checkUserCountry(e.target.value)
  }

  const changeUserState = (e) => {
    setUserState(e.target.value)
    checkUserState(e.target.value)
  }

  const changePassword = (e) => {
    setPassword(e.target.value)
    checkPassword(e.target.value)
  }

  const changeReportPhone = (e) => {
    setReportPhone(e.target.value)
    checkReportPhone(e.target.value)
  }

  useEffect(() => {
    if (!imgupload) {
      setImgPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(imgupload)
    setImgPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [imgupload])

  useEffect(() => {
    if (!imgsigupload) {
      setImgsigPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(imgsigupload)
    setImgsigPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [imgsigupload])

  useEffect(() => {
    if (state.user) {
      setFirstName(state.user.attributes['first-name']);
      setLastName(state.user.attributes['last-name']);
      setEmail(state.user.attributes['email']);
      setPhone(state.user.attributes['phone']);
      setUserCountry(state.user.attributes['country']);
      setUserState(state.user.attributes['state']);
      setPostcode(state.user.attributes['postcode']);
      setRfn(state.user.attributes['rfn']);
      setAbn(state.user.attributes['abn']);
      setQbcc(state.user.attributes['qbcc']);
      setReportPhone(state.user.attributes['reportphone']);
      // setReportPhone(state.user.attributes['reportphone']);
      setImgurl(state.user.attributes['imgurl'])
      setSignatureurl(state.user.attributes['signatureurl'])
      if (state.user.attributes['imgurl']) setLogox(true);
      if (state.user.attributes['signatureurl']) setSignature(true);

      const wholesalersFilter = "?include=wholesalerAuthResource";
      getWholesalers(wholesalersFilter).then(response => {
        let displayedWS = [];
        response.data.forEach(ws => {
          displayedWS.push({
            label: ws.attributes['name'],
            value: ws.id
          })
        })
        displayedWS.push({
          label: 'Other',
          value: 'other'
        })
        setDisplayWholesalers(displayedWS);
        let hasWS = false;
        response.data.forEach(ws => {
          if (state.user.relationships.wholesaler && 
            state.user.relationships.wholesaler.data && 
            ws.id === state.user.relationships.wholesaler.data.id) {
            setWholesaler(ws.id);
            hasWS = true;
          }
        })
        if (!hasWS) {
          setWholesaler('other');
        }
      })
    }
  }, [state.user])

  const inputImgRef = useRef(null);
  const inputImgsigRef = useRef(null);

  return (
    <form id="user-form" className="form">
      <div className="row row-animated">
        <FormValidatedInput type='text' name='firstName' value={firstName} errorMessage={errorMessageFirstName} showErrorMessage={showErrorMessageFirstName} disabled={inputsDisabled} didValidate={didValidate} labelText='First Name' onChange={changeFirstName}/>
      </div>
    
      <div className="row row-animated">
        <FormValidatedInput type='text' name='lastName' value={lastName} errorMessage={errorMessageLastName} showErrorMessage={showErrorMessageLastName} disabled={inputsDisabled} didValidate={didValidate} labelText='Last Name' onChange={changeLastName}/>
      </div>
    
      <div className="row row-animated">
        <FormValidatedInput type='text' name='email' value={email} errorMessage={errorMessageEmail} showErrorMessage={showErrorMessageEmail} disabled={inputsDisabled} didValidate={didValidate} labelText='Email' onChange={changeEmail}/>
      </div>
    
      <div className="row row-animated">
        <FormValidatedInput type='text' name='phone' value={phone} errorMessage={errorMessagePhone} showErrorMessage={showErrorMessagePhone} disabled={inputsDisabled} didValidate={didValidate} labelText='Phone' onChange={changePhone}/>
      </div>
    
      <div className="row row-animated">
        <FormValidatedSelect type='text' name='country' options={countries} value={userCountry} errorMessage={errorMessageUserCountry} showErrorMessage={showErrorMessageUserCountry} disabled={inputsDisabled} didValidate={didValidate} labelText='Country' onChange={changeUserCountry}/>
      </div>
    
      <div className="row row-animated">
        <FormValidatedSelect type='text' name='state' options={userCountry === 'Australia' ? australianStates : userCountry === 'New Zealand' ? newZealandStates : []} value={userState} errorMessage={errorMessageUserState} showErrorMessage={showErrorMessageUserState} disabled={inputsDisabled} didValidate={didValidate} labelText='State' onChange={changeUserState}/>
      </div>
    
      <div className="row row-animated">
        <FormValidatedWholesalerSelect type='text' name='wholesaler' options={displayWholesalers} value={wholesaler} disabled={inputsDisabled} didValidate={didValidate} labelText='Preferred Wholesaler' onChange={changePreferredWholesaler} />
      </div>
    
      <div className="row row-animated">
        <FormValidatedInput type='text' name='postcode' value={postcode} disabled={inputsDisabled} didValidate={didValidate} labelText='Post code' onChange={e => setPostcode(e.target.value)}/>
      </div>
      <br/>
      Reports Section
      <br/>
    
      <div className="row row-animated">
        <FormValidatedInput type='text' name='RFN' value={rfn} disabled={inputsDisabled} didValidate={didValidate} labelText='Report Full Name' onChange={e => setRfn(e.target.value)}/>
      </div>
    
      <div className="row row-animated">
        <FormValidatedInput type='text' name='ABN' value={abn} disabled={inputsDisabled} didValidate={didValidate} labelText='ABN Number' onChange={e => setAbn(e.target.value)}/>
      </div>
    
      <div className="row row-animated">
        <FormValidatedInput type='text' name='QBCC' value={qbcc} disabled={inputsDisabled} didValidate={didValidate} labelText='Contractor license number' onChange={e => setQbcc(e.target.value)}/>
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='Reportphone' value={reportPhone} disabled={inputsDisabled} didValidate={didValidate} labelText='Contact Phone Number' onChange={changeReportPhone}/>
      </div>
    <div style={{paddingTop: '15px'}}>
      Business Logo
      {!imgurl ? (
        <>
          <br/>
          Current Business Logo: Logo not set yet
          {imginfo}
        </>
      ):(
        imginfo
      )}

      {!inputsDisabled && (
        <div style={{textAlign: 'left', color: editPage? 'black' : '#95c11f', display: 'flex', flexDirection: 'column'}}>
          <input type="file" accept=".png,.jpeg,.jpg" ref={inputImgRef} onChange={(e) => setImgupload(e.target.files[0])} style={{display: 'none'}} />
          {imgPreview && <img src={imgPreview} style={{maxWidth: '100%', maxHeight: '200px', objectFit: 'contain'}}/>}
          {imgPreview ? 'Image set and ready to upload' : (
            <div 
              onDragOver={e => {
                e.preventDefault();
              }} 
              onDrop={e => {
                e.preventDefault();
                [...e.dataTransfer.items].forEach((item, i) => {
                  if (item.kind === 'file') {
                    const file = item.getAsFile();
                    setImgupload(file);
                  }
                });
              }}
            >
              <b style={{borderStyle: 'solid', borderWidth: '3px'}} onClick={() => {inputImgRef.current.click()}}>
                Upload Business Logo: Drag here or click to upload a file
              </b>
            </div>
          )}
        </div>
      )}
    
      <img style={{maxWidth: '500px'}} src={imgurl} />
      {logox && isEditing && (
        <button className='button standard green space-right' type="button" name="delete" disabled={isQueryPending} onClick={deletelogo} >Delete Image</button>
      )}
    </div>
    
      Signature
      {!inputsDisabled && (
        <div style={{textAlign: 'left', color: editPage? 'black' : '#95c11f', display: 'flex', flexDirection: 'column'}}>
          <input type="file" accept=".png,.jpeg,.jpg" ref={inputImgsigRef} onChange={(e) => setImgsigupload(e.target.files[0])} style={{display: 'none'}} />
          {imgsigPreview && <img src={imgsigPreview} style={{maxWidth: '100%', maxHeight: '200px', objectFit: 'contain'}}/>}
          {imgsigPreview ? 'Image set and ready to upload' : (
            <div 
              onDragOver={e => {
                e.preventDefault();
              }} 
              onDrop={e => {
                e.preventDefault();
                [...e.dataTransfer.items].forEach((item, i) => {
                  if (item.kind === 'file') {
                    const file = item.getAsFile();
                    setImgsigupload(file);
                  }
                });
              }}
            >
              <b style={{borderStyle: 'solid', borderWidth: '3px'}} onClick={() => {inputImgsigRef.current.click()}}>
                Upload Signature: Drag here or click to upload a file
              </b>
            </div>
          )}
        </div>
      )}
      <br/>
      Current signature:  {sigimginfo}
    
      {!signature && 'Signature not set yet.'}

      {signature && imgbeeninguploadedalready && 'Image set and ready to upload'}

      {signature && <img style={{maxWidth: '500px'}} src={signatureurl} />}

      {signature && isEditing && <button className='button standard green space-right' type="button" name="delete" disabled={isQueryPending} onClick={deletesig}>Delete Image</button>}
    
      {showWholesalerAuthFields && (
        <>
          <div className="wholesaler-account-heading">
            Add your {wholesaler && wholesaler.name} Account Details (Optional)
          </div>
          <div className="row row-animated user-name">
            <FormValidatedInput name='username' value={username} labelText='Username' />
          </div>
          <div className="row row-animated password">
            <FormValidatedInput type='password' name='password' value={password} labelText='Password' />
          </div>
        </>
      )}
    
      <div className="actions edit-actions">
        {isEditing ? (
          <>
            <button className='button standard blue cancel' type="button" name="Cancel" onClick={cancel} >Cancel</button>
            <button className='button standard green space-right' type="button" name="Save" disabled={isQueryPending} onClick={save} >Save</button>
          </>
        ):(
          <>
            <button className='button standard text-red' onClick={onDeleteAccountModal}>
              <img src={delete_icon} className='icon delete-icon' alt="Delete Icon"/>
                Delete Account
            </button>
            <button className='button standard green' type="button" name="Edit" onClick={edit}>Edit</button>
          </>
        )}
      </div>
    </form>
  )
}